/* eslint-disable max-len */
import { useRef, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Navigation, Pagination } from 'swiper';
import { AutoplayOptions } from 'swiper/types';
import { twMerge } from 'tailwind-merge';
import 'swiper/css';
import dynamic from 'next/dynamic';

import { useSwiperNavigation } from 'epromo-lib/hooks/useSwiperNavigation';
import { useMediaQuery } from 'epromo-lib/hooks/useMediaQuery';
import { useInitialCategories } from 'epromo-lib/hooks/useInitialCategories';
import { screens } from 'epromo-lib/utils/tailwindTheme';
import { IBannerCarouselBlock } from 'epromo-lib-cms/types/payload-types';

import { Icon } from '@components/atoms/Icon';
import { BannerSlide } from '../BannerSlide';

const bannerPaginationClass = 'banner-pagination';

const CategoryMainNavigation = dynamic(
  () =>
    import('@components/organisms/CategoryMainNavigation').then(
      (mod) => mod.CategoryMainNavigation
    ),
  {
    ssr: true,
    loading: () => <p>...</p>,
  }
);

export function BannerCarousel({
  bannerSlides,
  deviceInfo,
}: IBannerCarouselBlock) {
  const notMobileClient = !deviceInfo?.isTablet && !deviceInfo?.isMobile;

  const { nextEl, prevEl, nextElRef, prevElRef } = useSwiperNavigation();
  const isMobileView = useMediaQuery(`(max-width: ${screens?.lg})`);
  const [hasNavEngaged, setHasNavEngaged] = useState(false);
  const [videoPlaying, setVideoPlaying] = useState(false);

  const categoryRoot = useRef<HTMLDivElement>(null);
  const { flatInventoryList, isLoading } = useInitialCategories(notMobileClient);

  if (!bannerSlides || !bannerSlides.length) null;

  const resolveAutoplay = (): boolean | AutoplayOptions | undefined => {
    if (isMobileView) return false;

    return {
      delay: videoPlaying ? 9999999 : 5000,
      pauseOnMouseEnter: true,
      disableOnInteraction: hasNavEngaged,
    };
  };

  const showNavigation = bannerSlides?.length > 1 && !isMobileView;

  const navButtonStyle = twMerge(
    'bg-white flex h-14 w-14 z-10',
    'items-center justify-center rounded-full text-secondary-500',
    'border-secondary-150',
    'border-[1px]'
  );

  const navBtnClass = twMerge(
    navButtonStyle,
    !showNavigation && notMobileClient && 'hidden'
  );

  return (
    <div className="flex flex-col items-start">
      <div className="flex w-full" ref={categoryRoot}>
        {notMobileClient && (
          <CategoryMainNavigation
            rootElement={categoryRoot?.current}
            flatInventoryList={flatInventoryList}
            isLoading={isLoading}
          />
        )}
        <div
          className={twMerge(
            'relative mb-14 mt-6 flex flex-col',
            'items-start justify-start overflow-x-hidden'
          )}
        >
          <div
            className={twMerge(
              'absolute hidden gap-x-3 md:flex',
              'left-[200px] top-[106px]',
              'md:left-[525px] md:top-[300px]',
              'lg:left-[684px] lg:top-[382px]',
              'xl:left-[794px] xl:top-[444px]'
            )}
          >
            <>
              <button ref={prevElRef} className={twMerge(navBtnClass)}>
                <Icon name="back" />
              </button>
              <button ref={nextElRef} className={twMerge(navBtnClass)}>
                <Icon name="next" />
              </button>
            </>
          </div>
          <div
            className={twMerge(
              'relative w-screen',
              'lg:w-[calc(100vw_-_315px)]'
            )}
          >
            <Swiper
              key={isMobileView ? 'mobile' : 'desktop'}
              slidesOffsetAfter={isMobileView ? 16 : 0}
              loop={notMobileClient && showNavigation}
              shortSwipes={!videoPlaying}
              autoplay={resolveAutoplay()}
              pagination={{
                clickable: true,
                el: `.${bannerPaginationClass}`,
                bulletClass: 'pagination-bullet',
                bulletActiveClass: 'pagination-bullet-active',
              }}
              slidesPerView={'auto'}
              navigation={
                notMobileClient && showNavigation ? { prevEl, nextEl } : false
              }
              modules={[Navigation, Pagination, Autoplay]}
              onNavigationPrev={() => {
                setHasNavEngaged(true);
              }}
              onNavigationNext={() => {
                setHasNavEngaged(true);
              }}
              lazy={true}
              watchSlidesProgress
            >
              {bannerSlides?.map((slide, idx) => {
                return (
                  <SwiperSlide
                    key={`${slide.id}-${isMobileView ? 'auto' : 'fixed'}`}
                    style={{
                      width: 'min-content',
                      paddingLeft: 16,
                    }}
                    className={
                      'h-[199px] md:h-[394px] lg:h-[498px] xl:h-[550px]'
                    }
                  >
                    <BannerSlide
                      idx={idx}
                      key={slide.id}
                      onVideoActivated={() => setVideoPlaying(true)}
                      onVideoStarted={() => setVideoPlaying(true)}
                      onVideoEnd={() => {
                        setVideoPlaying(false);
                        if (nextElRef && typeof nextElRef === 'object') {
                          nextElRef.current?.click();
                        }
                      }}
                      {...slide}
                    />
                  </SwiperSlide>
                );
              })}
            </Swiper>
          </div>
        </div>
      </div>
      <div
        className={twMerge(
          bannerPaginationClass,
          'flex w-full justify-center gap-x-2',
          '-mt-8 mb-5 min-h-[8px]'
        )}
      ></div>
    </div>
  );
}
