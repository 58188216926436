import { useRef } from 'react';
import { useTranslation } from 'next-i18next';
import { twMerge } from 'tailwind-merge';

import { useElementDimensions } from 'epromo-lib/hooks/useElementDimensions';
import { useMediaQueries } from 'epromo-lib/hooks/useMediaQueries';
import { screens } from 'epromo-lib/utils/tailwindTheme';
import { IBannerSlide } from 'epromo-lib-cms/types/payload-types';

import { Button, ButtonAppearance } from '@components/atoms/Button';
import { Img } from '@components/molecules/Img';

import { CmsLink } from '../CmsLink';
import { VideoBannerSlide, containerClassnames } from './VideoBannerSlide';

interface SlideData {
  onVideoEnd?: () => void;
  onVideoStarted?: () => void;
  onVideoActivated?: () => void;
  idx: number;
}

const imageFrameMap: Record<IBannerSlide['imageFraming'], string> = {
  none: 'object-contain',
  fluid: '',
  frame_inverted_d: twMerge(
    'rounded-l-[50px] rounded-r-[5px]',
    'md:rounded-l-[200px] md:rounded-r-[20px]'
  ),
  frame_a: twMerge(
    'rounded-b-[5px] rounded-t-[50px]',
    'md:rounded-b-[20px] md:rounded-t-[200px]'
  ),
};

export function BannerSlide({
  backgroundColor,
  type,
  mainImage,
  link,
  comment,
  title,
  imageFraming,
  onVideoStarted,
  onVideoEnd,
  onVideoActivated,
  idx,
}: IBannerSlide & SlideData) {
  const { t } = useTranslation('common');
  const [isMd] = useMediaQueries([`(min-width: ${screens?.md})`]);
  const slideRef = useRef<HTMLDivElement>(null);
  const { width, height } = useElementDimensions(slideRef);

  const containerStyles = twMerge(
    containerClassnames,
    type === 'internal' && 'grid grid-cols-3 md:grid-cols-2'
  );

  const needPadding = ['frame_inverted_d', 'frame_a', 'none'].includes(
    imageFraming
  );

  if (type === 'video') {
    return (
      <VideoBannerSlide
        onVideoStarted={onVideoStarted}
        onVideoActivated={onVideoActivated}
        onVideoEnded={onVideoEnd}
        link={link}
      />
    );
  }

  return (
    <CmsLink link={link}>
      <div
        ref={slideRef}
        style={{ backgroundColor }}
        className={twMerge(
          containerStyles,
          'border-secondary-150 box-border',
          'border-[1px]'
        )}
      >
        {type === 'custom' && mainImage.url && (
          <Img
            className="h-full object-fill"
            url={mainImage.url}
            alt={mainImage.alt}
            height={height}
            width={width}
            isLazy={idx !== 0}
            fetchPriority={idx === 0 ? 'high' : 'auto'}
          />
        )}
        {type === 'internal' && (
          <>
            <div
              className={twMerge(
                'col-span-2 grid',
                'grid-cols-1 grid-rows-3 md:col-span-1',
                'gap-1 md:gap-8',
                'px-4 pl-4',
                'md:px-8 md:pl-8',
                'lg:px-12 lg:pl-12',
                'xl:px-16 xl:pl-16'
              )}
            >
              {!!comment && (
                <span
                  className={twMerge(
                    'row-start-1 self-end',
                    'bg-secondary-100 rounded-2xl px-2 py-1 md:px-6',
                    'h-fit w-fit text-[10px] font-medium text-neutral-900',
                    'md:text-xs md:uppercase lg:text-sm'
                  )}
                >
                  {comment}
                </span>
              )}
              <h3
                className={twMerge(
                  'text-secondary-100 font-bold',
                  'row-start-2 text-xs md:text-xl',
                  'lg:text-2xl lg:font-black xl:text-4xl'
                )}
              >
                {title}
              </h3>
              <Button
                appearance={ButtonAppearance.SECONDARY}
                className={twMerge(
                  'bg-secondary-500 hover:bg-neutral-900',
                  'active:bg-secondary-500 row-start-3 px-16 text-white'
                )}
              >
                {t('more')}
              </Button>
            </div>

            <div
              className={twMerge(
                'col-span-1 self-center justify-self-center',
                needPadding && 'justify-self-end p-2 md:p-6'
              )}
            >
              <Img
                className={twMerge(
                  'object-cover',
                  imageFrameMap[imageFraming] || ''
                )}
                url={mainImage.url}
                alt={mainImage.alt}
                height={
                  height
                    ? height - (needPadding ? (isMd ? 72 : 16) : 0)
                    : undefined
                }
                width={undefined}
                isLazy={idx !== 0}
                fetchPriority={idx === 0 ? 'high' : 'auto'}
              />
            </div>
          </>
        )}
      </div>
    </CmsLink>
  );
}
