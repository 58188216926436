import { useSwiperSlide } from 'swiper/react';
import { CSSProperties, useEffect, useState } from 'react';
import { twMerge } from 'tailwind-merge';
import ReactPlayer from 'react-player';
import clsx from 'clsx';

import { useMediaQuery } from 'epromo-lib/hooks/useMediaQuery';
import { screens } from 'epromo-lib/utils/tailwindTheme';
import { ILink } from 'epromo-lib-cms/types/payload-types';

interface Props {
  link: ILink;
  onVideoEnded?: () => void;
  onVideoStarted?: () => void;
  onVideoActivated?: () => void;
}

export const containerClassnames = clsx(
  'h-[182px] w-[324px]',
  'md:h-[377px] md:w-[669px]',
  'lg:h-[458px] lg:w-[813px]',
  'xl:h-[520px] xl:w-[923px]',
  'overflow-hidden rounded-[20px]',
  'box-content block',
  'shadow-lg'
);

export const VideoBannerSlide = ({
  link,
  onVideoEnded,
  onVideoStarted,
  onVideoActivated,
}: Props) => {
  const { isVisible, isActive } = useSwiperSlide();
  const inVisibleAndActive = isVisible && isActive;
  const [isVidPlaying, setIsVidPlaying] = useState(inVisibleAndActive);
  const isMobileView = useMediaQuery(`(max-width: ${screens?.lg})`);

  useEffect(() => {
    setIsVidPlaying(inVisibleAndActive);
    if (inVisibleAndActive) {
      onVideoActivated?.();
    }
  }, [inVisibleAndActive, onVideoActivated]);

  const safariCompatibleRadius = {
    '-webkit-mask-image':
      '-webkit-radial-gradient(circle, white 100%, black 100%)',
  } as CSSProperties;

  return (
    <div
      className={twMerge(containerClassnames, 'cursor-pointer')}
      onClick={() => setIsVidPlaying(!isVidPlaying)}
    >
      <div
        className={clsx('h-[inherit] rounded-[20px] bg-red-50 object-cover')}
        style={safariCompatibleRadius}
      >
        <ReactPlayer
          url={link.url}
          muted
          playing={isVidPlaying}
          onStart={() => inVisibleAndActive && onVideoStarted?.()}
          loop={isMobileView}
          onEnded={() => inVisibleAndActive && onVideoEnded?.()}
          controls={false}
          width="100%"
          height="100%"
        />
      </div>
    </div>
  );
};
